var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5"},[_c('v-data-table',{attrs:{"items":_vm.categories,"headers":_vm.headers,"dense":"","items-per-page":100},scopedSlots:_vm._u([{key:"item.src",fn:function(ref){
var item = ref.item;
return [(!!item.src)?_c('v-img',{staticClass:"my-2",attrs:{"src":item.src,"lazy-src":item.src,"max-width":"170px","width":"170px"}}):_c('v-img',{staticClass:"my-2",attrs:{"src":require("../../../../assets/fallback-img.svg"),"lazy-src":require("../../../../assets/fallback-img.svg"),"max-width":"170px","width":"170px"}},[_c('v-overlay',{attrs:{"absolute":"","color":"#fff"}},[_c('v-icon',{attrs:{"color":"#46434f"}},[_vm._v("mdi-file-image")])],1)],1)]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.truncate(item.description))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.menuItems),function(el,index){return _c('v-list-item',{key:index,on:{"click":function($event){return el.action(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","left":""},domProps:{"textContent":_vm._s(el.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(el.title))])],1)],1)}),1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }