<template>
  <v-card class="mt-5">
    <v-data-table
      :items="categories"
      :headers="headers"
      dense
      :items-per-page="100"
    >
      <!--Featured Image-->
      <template v-slot:item.src="{ item }">
        <v-img
          v-if="!!item.src"
          class="my-2"
          :src="item.src"
          :lazy-src="item.src"
          max-width="170px"
          width="170px"
        ></v-img>
        <!--Fallback image-->
        <v-img
          v-else
          class="my-2"
          src="../../../../assets/fallback-img.svg"
          lazy-src="../../../../assets/fallback-img.svg"
          max-width="170px"
          width="170px"
        >
          <v-overlay absolute color="#fff">
            <v-icon color="#46434f">mdi-file-image</v-icon>
          </v-overlay>
        </v-img>
      </template>
      <!--Description-->
      <template v-slot:item.description="{ item }">
        {{ truncate(item.description) }}
      </template>
      <!--Actions-->
      <template v-slot:item.id="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small icon v-bind="attrs" v-on="on"
              ><v-icon>mdi-dots-vertical</v-icon></v-btn
            >
          </template>
          <v-list>
            <v-list-item
              v-for="(el, index) in menuItems"
              :key="index"
              @click="el.action(item)"
            >
              <v-list-item-icon>
                <v-icon small left v-text="el.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ el.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import store from "../../store";
import { mapState } from "vuex";
export default {
  name: "CategoryTable",
  data() {
    return {
      headers: [
        { text: "Featured Image", value: "src", align: "start" },
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Actions", value: "id", align: "right" },
      ],
      menuItems: [
        {
          title: "Edit Category",
          icon: "mdi-pencil",
          action: (el) =>
            this.$router.push({
              name: "ecommerce-edit-category",
              params: { id: el.id },
            }),
        },
      ],
    };
  },
  computed: {
    ...mapState(["categories"]),
  },
  store,
  methods: {
    //
    truncate(item) {
      return !item || item.length < 50 ? item : `${item.slice(0, 50)}...`;
    },
  },
};
</script>